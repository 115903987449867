import React from "react";
import PortableText from "./portableText";

const DefaultContent = (props) => {
  const {
    title,
    content
  } = props;

  return (
    <div>
      <div className="grid">
        <div className="col-12 col-md-6">
          <div className="content-sm">
            {title && (<h1>{title}</h1>)}
          </div>
        </div>
        <div className="col-12 col-md-6">
          <div className="content-lg">
            {content && (<PortableText blocks={content} />)}
          </div>
        </div>
      </div>
    </div>
  );
}

export default DefaultContent;
