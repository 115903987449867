import React from "react";
import { cn } from "../lib/helpers";
import PortableText from "./portableText";

import * as styles from "../styles/components/page-additional-content.module.css";

function AdditionalContent({ content }) {
  return (
    <div className={styles.root}>
      {content.map(({ _key, heading, _rawContent }) => {
        return (
          <div className={cn("grid", styles.contentRow)} key={_key}>
            <div className="col-12 col-md-6">
              <div className="content-sm">
                {heading && (<h2 className={cn("h1", styles.heading)}>{heading}</h2>)}
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="content-lg">
                {_rawContent && (<PortableText blocks={_rawContent} />)}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default AdditionalContent;
