import React from "react";
import { graphql } from "gatsby";
import Layout from "../containers/layout";
import GraphQLErrorList from "../components/graphql-error-list";
import Seo from "../components/seo";

import PageMain from "../components/page-main";

export const query = graphql`
  query PageTemplateQuery($id: String!) {
    page: sanityPage(id: { eq: $id }) {
      id
      description
      title
      _rawContent
      contentSections {
        _key
        heading
        _rawContent
      }
      smallBodyCopy
    }
  }
`;

const PageTemplate = (props) => {
  const { 
    data, 
    errors
  } = props;

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }
  
  const page = (data || {}).page;

  return (
    <Layout>
      <Seo
        title={page.title}
        description={page.description}
      />

      {(page.title || page._rawContent || (page.contentSections && page.contentSections.length)) ? <PageMain title={page.title} defaultContent={page._rawContent} additionalContent={page.contentSections} smallBodyCopy={page.smallBodyCopy} /> : null}
    </Layout>
  );
};

export default PageTemplate;
